<template>
  <Container v-if="$preview && !sale">
    <DevNote>
      Alchemy Preview: This "Page hero oneozsale" is only visible on the
      storefront during an active one-oz-sale.</DevNote
    >
  </Container>
  <HeaderAlternate
    v-if="$preview || sale"
    :src="image"
    :alt="imageAltText"
    :desktop-srcset="desktopSrcset"
    :mobile-srcset="mobileSrcset"
    :headline="headline"
    :paragraph="paragraph"
    :terms="terms"
    :button-label="buttonLabel"
    :button-href="buttonHref"
    :background-color="backgroundColor"
    :light-text="lightText"
  />
</template>

<script setup lang="ts">
  import HeaderAlternate from "~/components/HeaderAlternate.vue"

  const saleStore = useSaleStore()
  const sale = computed(() => saleStore.activeSale)
  const { $preview } = useNuxtApp()

  const { getValue, getIngredient } = useAlchemy()

  const props = defineProps<{
    element: AlchemyElement
  }>()

  const headline = getValue<string>(props.element, "headline") || ""
  const paragraph = getValue<string>(props.element, "paragraph") || ""
  const terms = getValue<string>(props.element, "terms") || ""

  const buttonHref =
    getIngredient<AlchemyIngredientText>(props?.element, "button")?.link || ""
  const buttonLabel = getValue<string>(props.element, "button") || ""

  const backgroundColor =
    getValue<string>(props.element, "background_color") || ""
  const lightText = getValue<boolean>(props.element, "light_text") || false

  const imageIngredient = getIngredient<AlchemyIngredientPicture>(
    props.element,
    "desktop_image",
  )
  const image = imageIngredient?.value || ""
  const desktopSrcset = imageIngredient?.srcset
  const imageAltText = imageIngredient?.altText || ""

  const mobileSrcset = getIngredient<AlchemyIngredientPicture>(
    props.element,
    "mobile_image",
  )?.srcset
</script>
